<!-- 店铺管理 -->
<template>
    <div>
        <div class="page-header">订单管理</div>
        <div class="flex">
            <div class="flex-1 block" style="overflow: auto;margin: 20px;background: white;">
                <div class="header">
                    <el-form :inline="true">
                        <el-form-item label="商品名称" style="margin-bottom: 0;">
                            <el-input></el-input>
                        </el-form-item>
                        <el-form-item label="订单编号" style="margin-bottom: 0;">
                            <el-input></el-input>
                        </el-form-item>
                        <el-form-item style="margin-bottom: 0;">
                            <el-button type="primary">查询</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div>
                    <el-table :data="rows" style="width: 100%" ref="_table">
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column prop="code" label="订单编号" width="80">
                        </el-table-column>
                        <el-table-column prop="item.code" label="商品编码" width="80">
                        </el-table-column>
                        <el-table-column prop="item.name" label="商品名称">
                        </el-table-column>
                        <el-table-column prop="item.cat.name" label="类目" width="80">
                        </el-table-column>
                        <el-table-column prop="item.customer.name" label="买家" width="80">
                        </el-table-column>
                        <el-table-column prop="state" label="订单状态" width="80">
                        </el-table-column>
                        <el-table-column prop="trade_date" label="交易时间">
                        </el-table-column>
                        <el-table-column prop="count" label="购买数量">
                        </el-table-column>
                        <el-table-column prop="price" label="单价">
                        </el-table-column>
                        <el-table-column prop="amount" label="总价">
                        </el-table-column>
                    </el-table>
                </div>
                <div style="text-align: right;">
                    <el-pagination background layout="prev, pager, next" :total="total"></el-pagination>
                </div>
            </div>
        </div>
        <el-dialog :title="popTitle" :visible.sync="popVisible">

        </el-dialog>
    </div>
</template>

<script>
    import { getOrders } from '@/service/taobao';
    export default {
        components: {

        },
        data() {
            return {
                popVisible: false,
                popTitle: '',
                popType: null,
                rows: [],
                total: 0
            }
        },
        created() {
            getOrders(this.$route.params.shop_id).then(rst=>{
                    console.log(666,rst);
                }).catch(err=>{
                    console.log(err);
                    this.$message.error(err.message);
                });
        },
        computed: {
            selectedRowIds: function () {
                let ids = this.$refs._table.store.states.selection.reduce((pre, cur) => {
                    pre.push(cur.id)
                    return pre;
                }, []);
                return ids;
            }
        },
        methods: {
            goto(path) {
                this.$router.push(path);
            },
            refreshRows() {
                // getMembersByDept(this.$store.state.loginUser.comp.id, this.dept_id).then(u=>{
                //     this.rows=u.list;
                //     this.total=u.total;
                // }).catch(err => {
                //     this.$message.error(err.message);
                // });
            },
            addShop() {

            },
            // 批量删除用户
            delRows() {
                if (this.selectedRowIds.length > 0) {
                    //     delUserByIds(this.selectedUserIds).then(()=>{
                    //         this.$message.success('删除成功');
                    //         this.refreshMembers();
                    //     }).catch(err => {
                    //     this.$message.error(err.message);
                    // });
                }
            },
            // 编辑
            popEditRow(row) {
                // this.$router.push('/contact_edit_user/'+row.id);
                // this.isEditingUser=true;
                // this.curEditUserId=row.id;
                // this.popType=2;
                this.popTitle = '编辑' + row.name;
                this.popVisible = true;
            },
            // 用户保存成功
            // onUserSaved(){
            //     this.popVisible=false;
            //     this.refreshMembers();
            // },
        }
    }
</script>
<style>

</style>
<style scoped src="../style.css"></style>
<style scoped>
    .header {
        background: rgba(245, 245, 245, 1);
    }

    .header>*:not(:last-child) {
        margin-right: 15px;
    }
</style>